import './App.scss';
import Logo from './img/home-logo.svg';

function App() {
  return (
    <div className="App">
      <header/>
      <main className="app-container">
        <img className="app-container-logo" src={Logo} alt="Espectro Estudio Logo"/>
      </main>
    </div>
  );
}

export default App;
